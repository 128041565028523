@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "./colors.css";


.sy_admin_manager_mtr {
    margin-top: 1rem;
}

.sy_admin_manager_delete_modal {
    margin-left: 1rem;
    margin-top: 1rem;
}

.sy_apiListProjectSelect > div{
    min-height: 120px;
}

.sy_allowedApiModalBody{
    height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
}

.sy_primary{
    color:  #007bff;
}